import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

// Project components
import { Box, Heading, Wrapper } from "components";

function Work({ title }) {
  const [hovered, setHovered] = useState(false);

  const { work } = useStaticQuery(graphql`
    {
      work: prismicWork {
        data {
          title {
            text
          }
          projects {
            project {
              document {
                ... on PrismicProject {
                  uid
                  data {
                    project_image {
                      fluid(maxWidth: 1024, maxHeight: 1024) {
                        ...GatsbyPrismicImageFluid
                      }
                      alt
                    }
                    name {
                      text
                    }
                    description {
                      html
                    }
                    services {
                      html
                    }
                    attribution {
                      html
                    }
                    client_logo {
                      fluid(maxWidth: 256, maxHeight: 256) {
                        ...GatsbyPrismicImageFluid
                      }
                      alt
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Wrapper id="work">
      <Box
        top={[0, "layout.2"]}
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          left: 0,
          objectFit: "cover",
        }}
      >
        <img
          src="/background-work.jpg"
          alt="background"
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            objectFit: "cover",
          }}
        />
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: hovered
              ? "rgba(32, 26, 33, 0.7)"
              : "rgba(32, 26, 33, 0.8)",
            top: 0,
            left: 0,
            backdropFilter: hovered ? "blur(6px)" : "blur(8px)",
            WebkitBackdropFilter: hovered ? "blur(6px)" : "blur(8px)",
            transition: "200ms ease-in-out all",
          }}
        />
      </Box>
      <a
        href="https://pitch.com/v/guerbois-creative---portfolio-current-2dfejt"
        target="_blank"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          textDecoration: "none",
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      />
      <div
        style={{
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          pointerEvents: "none",
        }}
      >
        <Heading
          as="h2"
          size={900}
          mb={["layout.4", "layout.6"]}
          textAlign={["center", "left"]}
          color="white"
          style={{
            transform: hovered ? "scale(1.02)" : "scale(1)",
            transition: "200ms ease-in-out transform",
          }}
        >
          Work →
        </Heading>
      </div>
    </Wrapper>
  );
}

export default Work;
